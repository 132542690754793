import React, { useContext } from 'react';
import * as S from './LockInSelectorV5.styles';
import FunnelContext from '../../../context/FunnelContext';

const CategoryTabs = ({ discountRate = 20, callback = () => {} }) => {
  const { currentCategory = 'subscriptions', setCurrentCategory } = useContext(
    FunnelContext
  );
  const isSub = currentCategory === 'subscriptions';

  const handleClick = cat => {
    setCurrentCategory(cat);
    callback(cat);
  };

  return (
    <>
      <S.Tabs>
        <S.Tab
          className={`subscriptions ${isSub ? `active` : ``}`}
          onClick={() => handleClick('subscriptions')}
        >
          <span>Subscribe & Save</span> <S.Rate>{discountRate}% OFF</S.Rate>
        </S.Tab>
        <S.Tab
          className={`onetime ${!isSub ? `active` : ``}`}
          onClick={() => handleClick('onetime')}
        >
          <span>One-Time</span>
        </S.Tab>
      </S.Tabs>
    </>
  );
};

export default CategoryTabs;
